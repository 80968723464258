// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-eventos-js": () => import("./../../../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacoes-js": () => import("./../../../src/pages/informacoes.js" /* webpackChunkName: "component---src-pages-informacoes-js" */),
  "component---src-pages-modalidades-js": () => import("./../../../src/pages/modalidades.js" /* webpackChunkName: "component---src-pages-modalidades-js" */),
  "component---src-pages-promocoes-js": () => import("./../../../src/pages/promocoes.js" /* webpackChunkName: "component---src-pages-promocoes-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */)
}

